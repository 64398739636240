import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Veritas Legal Insights
			</title>
			<meta name={"description"} content={"Чесність і прозорість на кожному кроці"} />
			<meta property={"og:title"} content={"Veritas Legal Insights"} />
			<meta property={"og:description"} content={"Чесність і прозорість на кожному кроці"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Components.Hero>
			<Override slot="text" text-transform="uppercase">
				Посильте свою подорож комплексними юридичними послугами
			</Override>
			<Override slot="text1">
				НАШІ ПОСЛУГИ
			</Override>
			<Override slot="text2" display="none">
				Тут ми розробляємо спектр наших пропозицій, спрямованих на
			</Override>
		</Components.Hero>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-8">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 50px 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px 0px 15px 0px"
					font="--lead"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
					sm-font="normal 600 28px/1.2 --fontFamily-sans"
					sm-text-align="left"
					sm-width="100%"
					text-align="center"
				>
					Тут ми розробляємо наш спектр пропозицій, покликаних задовольнити всі ваші юридичні потреби. Завдяки прагненню до досконалості та клієнтоорієнтованому підходу, наші послуги розроблені таким чином, щоб розширити ваші можливості, захистити вас та провести вас через будь-які юридичні виклики та можливості. Дізнайтеся, як ми можемо допомогти вам перетворити юридичну складність на ясність і дію.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-around"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,--color-primary 0%,--color-darkL2 100%) 0 0 no-repeat"
					height="auto"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="--headline3" color="--light" text-align="left">
							Бізнес-право та комплаєнс
						</Text>
						<Text
							font="--base"
							color="--light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Створення та структура підприємств: Поради щодо найкращої юридичної структури для вашого бізнесу з метою оптимізації зростання та правового захисту. Контракти та угоди: створення та аналіз контрактів, які захищають ваші інтереси та сприяють розвитку вашого бізнесу. Комплаєнс: забезпечення відповідності ваших бізнес-операцій чинному законодавству та нормативним актам, мінімізація ризиків та підвищення довіри до вас.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-around"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,--color-primary 0%,--color-darkL2 100%) 0 0 no-repeat"
					height="auto"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="--headline3" color="--light" text-align="left">
							Захист інтелектуальної власності
						</Text>
						<Text
							font="--base"
							color="--light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Реєстрація торгових марок та авторських прав: захист вашого бренду та творчих робіт для збереження конкурентних переваг. Супровід судових спорів у сфері інтелектуальної власності: Захист ваших прав інтелектуальної власності від порушень та несанкціонованого використання. Патентна стратегія: управління патентним процесом для забезпечення та захисту ваших інновацій.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,--color-primary 0%,--color-darkL2 100%) 0 0 no-repeat"
					height="auto"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="--headline3" color="--light" text-align="left">
							Послуги з сімейного права
						</Text>
						<Text
							font="--base"
							color="--light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Розлучення та розставання: співчутлива підтримка та стратегічне керівництво під час складних шлюборозлучних процесів. Опіка та підтримка дітей: захист ваших прав та найкращих інтересів ваших дітей у питаннях опіки та підтримки. Шлюбні угоди: укладення чітких, справедливих угод, які захищають інтереси обох сторін до шлюбу.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-around"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,--color-primary 0%,--color-darkL2 100%) 0 0 no-repeat"
					height="auto"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/" text-align="left">
						<Text margin="0px 0px 0 0px" font="--headline3" color="--light" text-align="left">
							Кримінальний захист
						</Text>
						<Text
							font="--base"
							color="--light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Водіння у нетверезому стані та порушення правил дорожнього руху: енергійний захист для захисту ваших прав та мінімізації наслідків звинувачень, пов'язаних з порушенням правил дорожнього руху. Захист від кримінальних злочинів та проступків: спеціалізоване представництво у широкому спектрі кримінальних звинувачень для досягнення найкращого результату. Зняття судимостей: допомога у знятті судимостей для відновлення репутації та відкриття нових можливостей.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-around"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,--color-primary 0%,--color-darkL2 100%) 0 0 no-repeat"
					height="auto"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="--headline3" color="--light" text-align="left">
							Нерухомість та майнове право
						</Text>
						<Text
							font="--base"
							color="--light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Операції з нерухомістю: професійні консультації та представництво при купівлі, продажу та оренді нерухомості. Спори між орендодавцями та орендарями: ефективне вирішення конфліктів, захист ваших прав як орендодавця або орендаря. Зонування та землекористування: ми допоможемо вам розібратися з місцевими правилами та дозволами на забудову та використання нерухомості.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-around"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,--color-primary 0%,--color-darkL2 100%) 0 0 no-repeat"
					height="auto"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/" text-align="left">
						<Text margin="0px 0px 0 0px" font="--headline3" color="--light" text-align="left">
							Трудовий кодекс
						</Text>
						<Text
							font="--base"
							color="--light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Права працівників та судові спори: сприяння справедливому ставленню на робочому місці, від скарг на дискримінацію до неправомірного звільнення. Трудові договори: гарантуємо, що ваші трудові договори є чіткими, справедливими та мають юридичну силу. Дотримання норм на робочому місці: допомагаємо роботодавцям орієнтуватися в складнощах трудового законодавства для створення відповідного та позитивного робочого середовища.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="link1" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text" />
		</Components.Contacts>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb673d867c86001f1b379d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});